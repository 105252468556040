export const ServerHTTPS = process.env.REACT_APP_ServerHTTPS;//  "http";
export const ServerWSS = process.env.REACT_APP_ServerWSS;//  "ws";
export const ServerDOMAIN = process.env.REACT_APP_ServerDOMAIN;//  "192.168.1.254";
export const ServerPORT = process.env.REACT_APP_ServerPORT;//  "8007";
export const ServerSERVICE = process.env.REACT_APP_ServerSERVICE;//  "";

export const MqWSS = process.env.REACT_APP_MqWSS;//  "ws";
export const MqHOST = process.env.REACT_APP_MqHOST;//  "192.168.1.254";
export const MqAmqpPORT = process.env.REACT_APP_MqAmqpPORT;//  "5672";
export const MqWebPORT = process.env.REACT_APP_MqWebPORT;//  "15672";
export const MqStompPORT = process.env.REACT_APP_MqStompPORT;//  "15674";
export const MqVirtualHOST = process.env.REACT_APP_MqVirtualHOST;//  "/";
export const MqUSERNAME = process.env.REACT_APP_MqUSERNAME;//  "test";
export const MqPASSWORD = process.env.REACT_APP_MqPASSWORD;//  "test";
export const MqEXCHANGE = process.env.REACT_APP_MqEXCHANGE;//  "Ludo_React";

export const ClientId = process.env.REACT_APP_ClientId;// "React-Ludo";

export const BASE_URL = `${ServerHTTPS}://${ServerDOMAIN}:${ServerPORT}` + (ServerSERVICE !== "" ? "/" + ServerSERVICE : "");

// Baser url for API
export const BASE_URL_API = BASE_URL + "/api";
// export const BASE_URL_V2 = BASE_URL_API + "/v2";
export const BASE_URL_V1 = BASE_URL_API + "/v1/ludo";
// export const BASE_URL_V3 = BASE_URL_API + "/v3";

const Player_Dashboard_Controller = `${BASE_URL_V1}/${ClientId}/player`;
export const Get_Profile = `${Player_Dashboard_Controller}/profile`;
export const Get_Template_List_GET = `${Player_Dashboard_Controller}/getTemplateList`;
export const Get_My_Running_Games_GET = `${Player_Dashboard_Controller}/myRunningGames`;
export const Get_My_Matching_Templates_GET = `${Player_Dashboard_Controller}/getMyMatchingTemplates`;