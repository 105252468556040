export const getTimeStringBySeconds = (sec) => {
    if (sec <= 0) {
        return ("00:00");
    }
    sec = Math.round(sec);
    var hours = Math.floor(sec / (60 * 60));

    var divisor_for_minutes = sec % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    return (
        (hours > 0 ? String(hours).padStart(2, '0') + ":" : "") +
        (String(minutes).padStart(2, '0') + ":") +
        String(seconds).padStart(2, '0')
    );
}