import React, { useState } from 'react';
import AvatarImage from './AvatarImage';
import Dice from './Dice';
import Heart from './Heart';
import Token from './Token';
import { Color } from '../utils/Constants';

const Chair = ({ color, index, tableModel, onTokenClick }) => {
    let chairDetail = tableModel?.chairs && tableModel?.chairs[index];
    const drawHeart = () => {
        const hearts = [];
        for (let index = 0; index < 3; index++) {
            hearts.push(<Heart broken={chairDetail.autoPlayCount > index} key={color + "_heart_" + index} />);
        }
        return hearts;
    }

    const drawToken = () => {
        // const tokens = [];
        let defaultClass = "";
        if (color === Color.BLUE) {
            defaultClass = "b--1";
        } else if (color === Color.GREEN) {
            defaultClass = "g--1";
        } else if (color === Color.RED) {
            defaultClass = "r--1";
        } else if (color === Color.YELLOW) {
            defaultClass = "y--1";
        }
        return (
            <>
                <div className={`drawToken ${defaultClass}`}>
                    <div className='d-flex justify-content-center align-items-center gap tokenContainer'>
                        {
                            chairDetail.tokenList.map(function (token, i) {
                                return <Token chairIndex={index} color={color}
                                    tokenIndex={token.tokenIndex}
                                    tokenPosition={token.tokenPosition}
                                    tokenScore={token.tokenScore} key={color + "_token_" + i}
                                    onTokenClick={(chairIndex, color, tokenIndex, tokenPosition) => {
                                        onTokenClick(chairIndex, color, tokenIndex, tokenPosition);
                                    }} />;
                            })
                        }
                    </div>
                </div>
            </>
        );
    }

    const drawChair = () => {
        return (
            <div className='player-sub-box-content'>
                <div className='w-100 d-flex justify-content-between py-2 px-3'>
                    <div>
                        <div className='heart-div d-flex align-items-center gap-5'>
                            <div className="heart-div d-flex align-items-center gap-5">
                                {
                                    drawHeart()
                                }
                            </div>
                        </div>
                        <div className="">
                            {
                                drawToken()
                            }
                        </div>
                    </div>
                    <Dice chairIndex={index} />
                </div>
                <div className='W-100'>
                    <div className='d-flex justify-content-between  align-items-center py-2 px-3'>
                        <AvatarImage imageUrl={chairDetail.playerModel.avatar} />
                        <div className="score-code text-white ">
                            <strong className="d-block ">{chairDetail.countScore}</strong>
                            <span>SCORE</span>
                        </div>
                    </div>
                    <div className="user-name">
                        <span className="text-white">{chairDetail.playerModel.playerName}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='weight-2 player-box'>
            <div className={`player-box-content player-box-${color}dark chair chair_${index}`}>
                {chairDetail !== undefined && chairDetail?.playerStatus !== "None" ? drawChair() : <></>}
            </div>
        </div>
    );
}

export default Chair;