export const getNumberSuffixes = (number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};

export const insertSpaceInKeyBeforeCapitalLatter = (string) => {
    return string.replace(/([A-Z])/g, ' $1').trim();
}
