import React, { useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import $ from "jquery";

// let percentage =10
const Dice = ({ chairIndex }) => {

    const [lastRandomNumber, setLastRandomNumber] = useState(0);
    window._rollDice = (chairIndex, number) => {
        const targetElement = document.getElementById(`dice-${chairIndex}`);
        var xRand = 0;
        var yRand = 0;
        switch (number) {
            case 1: xRand = 360; yRand = 360; break;
            case 2: xRand = 180; yRand = 360; break;
            case 3: xRand = 360; yRand = 270; break;
            case 4: xRand = 360; yRand = 90; break;
            case 5: xRand = 270; yRand = 360; break;
            case 6: xRand = 90; yRand = 360; break;
            default: xRand = 0; yRand = 0;
        }
        let minNumber = 3;
        let maxNumber = 10;
        let randNumber = 0;
        do {
            randNumber = (Math.floor(Math.random() * (maxNumber - minNumber)) + minNumber);
        }while(lastRandomNumber === randNumber);
        setLastRandomNumber(randNumber);
        xRand = xRand + (360 * randNumber);
        yRand = yRand + (360 * randNumber);
        targetElement.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';
        targetElement.style.transform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)';
    }

    window._showDefaultDice = () => {
        $(".dice").css("display", 'none');
        $(".default-dice").css("display", 'block');
        $(".dice").css("webkitTransform", '');
        $(".dice").css("transform", '');
    }

    window._showChairDice = (loggedInPlayerChairIndex, chairIndex) => {
        $(`#dice-${chairIndex}`).css("display", 'block');
        $(`#default-dice-${chairIndex}`).css("display", 'none');
        if (chairIndex === loggedInPlayerChairIndex) {
            $(`#my-dice`).css("display", 'block');
            $(`#my-default-dice`).css("display", 'none');
        }
    }

    // let interval = undefined;
    // window._showDiceProgress = (chairIndex, maxTime, elapsedTime) => {
    //     if(interval !== undefined) {
    //         clearInterval(interval);
    //     }
    //     let dataPercentage = 100;
    //     $(`#dice-${chairIndex}-progress_bar`).attr("data-percentage", 0);
    //     interval = setInterval(() => {
    //         dataPercentage = 100 - (elapsedTime * 100 / maxTime);
    //         console.log("------", dataPercentage);
    //         if(dataPercentage < 0) {
    //             dataPercentage = 0;
    //         }
    //         $(`#dice-${chairIndex}-progress_bar`).attr("data-percentage", 100 - dataPercentage);
    //         elapsedTime = elapsedTime + 1;
    //         if(dataPercentage === 0) {
    //             clearInterval(interval);
    //         }
    //     }, 1000);
    // }

    return (
        <div className={`dice-player-box `}>
            <div className='progress-box'>
                <div className='0' id={`dice-${chairIndex}-progress_bar`} data-percentage={60}>
                    <span className="progress-left">
                        <span className="progress-bar" />
                    </span>
                    <span className="progress-right">
                        <span className="progress-bar" />
                    </span>
                </div>
            </div>
            <div className="player-dice" >
                <div id={`dice-${chairIndex}`} className={`dice dice-one`}>
                    <div className="front">
                        <span className="dot dot1"></span>
                    </div>
                    <div className="back">
                        <span className="dot dot1"></span>
                        <span className="dot dot2"></span>
                    </div>
                    <div className="right">
                        <span className="dot dot1"></span>
                        <span className="dot dot2"></span>
                        <span className="dot dot3"></span>
                    </div>
                    <div className="left">
                        <span className="dot dot1"></span>
                        <span className="dot dot2"></span>
                        <span className="dot dot3"></span>
                        <span className="dot dot4"></span>
                    </div>
                    <div className="top">
                        <span className="dot dot1"></span>
                        <span className="dot dot2"></span>
                        <span className="dot dot3"></span>
                        <span className="dot dot4"></span>
                        <span className="dot dot5"></span>
                    </div>
                    <div className="bottom">
                        <span className="dot dot1"></span>
                        <span className="dot dot2"></span>
                        <span className="dot dot3"></span>
                        <span className="dot dot4"></span>
                        <span className="dot dot5"></span>
                        <span className="dot dot6"></span>
                    </div>
                </div>
                <img id={`default-dice-${chairIndex}`}
                    className='default-dice'
                    src={`assets/img/game_play/default-dice.png`} alt="Dice"
                    style={{
                        width: "100%",
                        display: "none"
                    }} />
            </div>
        </div>
    );
}

export default Dice;