import React, { useState, useEffect } from 'react';
import { Color } from '../utils/Constants';
import $ from "jquery";

const Token = ({ chairIndex, color, tokenIndex, tokenPosition, tokenScore, onTokenClick }) => {
    useEffect(() => {
        try {
            window._rePositionToken(chairIndex, color, tokenIndex, tokenPosition);
        } catch (err) {
            console.log(err);
        }
    }, [tokenPosition]);

    return (
        <span id={"token_" + color + "_" + tokenIndex}
            className={`token token_${chairIndex} token_${chairIndex}_${tokenIndex}`}
            token_score={tokenScore} color={color}
            onClick={() => onTokenClick(chairIndex, color, tokenIndex, tokenPosition)}>
            <span className='TokenInnerBox'>
                <img src={`assets/img/game_play/token_${color}.png`} alt="Token" className="img2" />
            </span>
        </span>
    );
}

export default Token;