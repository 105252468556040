import React from 'react'

const MatchingPlayer = ({ playreAvatar, playerName, entryFee }) => {
    return (
        <>
            <span className='player-profile-img mb-2 '>
                <img src={playreAvatar ? playreAvatar : "assets/img/game_play/avtar1.png"} alt="user" />
            </span>
            {
                playerName ? <>
                    <span className='player_name'>{playerName}</span>
                    <div className='player_amount'>
                        {/* <span className='mr-2'><img src="assets/img/game_play/rupee.png" alt="user" /></span> */}
                        <span>{entryFee.toFixed(2)}</span>
                    </div>
                </> : <></>
            }
        </>
    )
}

export default MatchingPlayer;