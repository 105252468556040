import React, { useEffect, useState } from "react";
import Spin from "./Spin";
// import CountDownTimer from '../utils/CountDownTimer'
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Footer = ({ tableModel, myChairIndex, onRollTheDice }) => {
    const [isAdding, setIsAdding] = useState();
    // const remainingTime = timer?.countDown - timer?.counter;

    const handleTokenScoreAdd = () => {
        setIsAdding(true);
        const elements = document.getElementsByClassName("boxes");
        // && element.hasChildNodes()
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element) {
                const tokenContainerEle = element.getElementsByClassName("tokenContainer")[0];
                if (tokenContainerEle.hasChildNodes()) {
                    element.classList.add("tokenScoreToggle");
                }
            }
        }
    };

    const handleTokenScoreRemove = () => {
        setIsAdding(false);
        const elements = document.getElementsByClassName("boxes");
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element) {
                element.classList.remove("tokenScoreToggle");
            }
        }
    };

    window._tokenScoreRemove = () => {
        handleTokenScoreRemove();
    };

    const [lastRandomNumber, setLastRandomNumber] = useState(0);
    window._rollMyDice = (number) => {
        const targetElement = document.getElementById(`my-dice`);
        var xRand = 0;
        var yRand = 0;
        switch (number) {
            case 1:
                xRand = 360;
                yRand = 360;
                break;
            case 2:
                xRand = 180;
                yRand = 360;
                break;
            case 3:
                xRand = 360;
                yRand = 270;
                break;
            case 4:
                xRand = 360;
                yRand = 90;
                break;
            case 5:
                xRand = 270;
                yRand = 360;
                break;
            case 6:
                xRand = 90;
                yRand = 360;
                break;
            default:
                xRand = 0;
                yRand = 0;
        }
        let minNumber = 3;
        let maxNumber = 10;
        let randNumber = 0;
        do {
            randNumber = Math.floor(Math.random() * (maxNumber - minNumber)) + minNumber;
        } while (lastRandomNumber === randNumber);
        setLastRandomNumber(randNumber);
        xRand = xRand + 360 * randNumber;
        yRand = yRand + 360 * randNumber;
        targetElement.style.webkitTransform = "rotateX(" + xRand + "deg) rotateY(" + yRand + "deg)";
        targetElement.style.transform = "rotateX(" + xRand + "deg) rotateY(" + yRand + "deg)";
    };

    // const [remainingTimer, setRemainingTimer] = useState(0);
    // useEffect(() => {
    //     if (tableModel !== null && tableModel !== undefined) {
    //         setRemainingTimer(
    //             tableModel.tableConfig.turnTime + tableModel.tableConfig.graceTime - tableModel.tableTurnTimer.counter
    //         );
    //     }
    // }, [tableModel]);

    const getTimerView = () => {
        return <></>;
        // if (tableModel === null || tableModel === undefined) {
        //     return (<></>);
        // }
        // for (let k = 0; k < tableModel.chairs.length; k++) {
        //     if (myChairIndex === k) {
        //         console.log(">>>>>>", tableModel.chairs[k].playerStatus, remainingTimer);
        //         if (tableModel.chairs[k].playerStatus === "DiceTurn" || tableModel.chairs[k].playerStatus === "TokenTurn") {
        //             return (<>
        //                 <CountdownCircleTimer
        //                     isPlaying
        //                     initialRemainingTime={tableModel.tableConfig.turnTime + tableModel.tableConfig.graceTime}
        //                     duration={remainingTimer}
        //                     colors={['#008000', '#ffa500', '#ff0000', '#ff0000']}
        //                     colorsTime={[7, 5, 2, 0]}
        //                 />
        //             </>);
        //         }
        //     }
        // }
        // return (<></>);
    };

    return (
        <>
            <div className=" d-flex  align-items-center justify-content-center ">
                <div className="col-4" />
                <div className="col-4">
                    <div>
                        <div className="mx-auto main-dice" id="footer-dice" onClick={() => onRollTheDice()}>
                            <div className="player-dice">
                                <div id="my-dice" className="dice dice-one">
                                    <div className="front">
                                        <span className="dot dot1"></span>
                                    </div>
                                    <div className="back">
                                        <span className="dot dot1"></span>
                                        <span className="dot dot2"></span>
                                    </div>
                                    <div className="right">
                                        <span className="dot dot1"></span>
                                        <span className="dot dot2"></span>
                                        <span className="dot dot3"></span>
                                    </div>
                                    <div className="left">
                                        <span className="dot dot1"></span>
                                        <span className="dot dot2"></span>
                                        <span className="dot dot3"></span>
                                        <span className="dot dot4"></span>
                                    </div>
                                    <div className="top">
                                        <span className="dot dot1"></span>
                                        <span className="dot dot2"></span>
                                        <span className="dot dot3"></span>
                                        <span className="dot dot4"></span>
                                        <span className="dot dot5"></span>
                                    </div>
                                    <div className="bottom">
                                        <span className="dot dot1"></span>
                                        <span className="dot dot2"></span>
                                        <span className="dot dot3"></span>
                                        <span className="dot dot4"></span>
                                        <span className="dot dot5"></span>
                                        <span className="dot dot6"></span>
                                    </div>
                                </div>
                                <img
                                    id={`my-default-dice`}
                                    className="default-dice"
                                    src={`assets/img/game_play/default-dice.png`}
                                    alt="Dice"
                                    style={{
                                        width: "100%",
                                        display: "none",
                                    }}
                                />
                            </div>
                        </div>
                        {getTimerView()}
                    </div>
                </div>
                <div className="col-4">
                    <span className="point_icon" onClick={isAdding ? handleTokenScoreRemove : handleTokenScoreAdd}>
                        <img src="/assets/img/game_play/point_icon.png" alt="" />
                    </span>
                    <div>
                        <span className="text-white">Points</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
