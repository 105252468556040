import React from 'react';
import { QuestionMarkIcon } from './common/SvgComponents';

export const LeftPath = () => {
    return (
        <div className="weight-2 flexColumn">
            <div className="weight-1 flexRow">
                <Box classes="g-12 r-25 b-38 y---" />
                <Box classes="g-13 r-26 b-39 y-00 yellow" isHouse={true} />
                <Box classes="g-14 r-27 b-40 y-01" />
                <Box classes="g-15 r-28 b-41 y-02" />
                <Box classes="g-16 r-29 b-42 y-03" />
                <Box classes="g-17 r-30 b-43 y-04" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-11 r-24 b-37 y-50" angle={"right"} />
                <Box classes="g--- r--- b--- y-51 yellow" />
                <Box classes="g--- r--- b--- y-52 yellow" />
                <Box classes="g--- r--- b--- y-53 yellow" />
                <Box classes="g--- r--- b--- y-54 yellow" />
                <Box classes="g--- r--- b--- y-55 yellow" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-10 r-23 b-36 y-49" />
                <Box classes="g-09 r-22 b-35 y-48" />
                <Box classes="g-08 r-21 b-34 y-47" isSafeHouse={true} />
                <Box classes="g-07 r-20 b-33 y-46" />
                <Box classes="g-06 r-19 b-32 y-45" />
                <Box classes="g-05 r-18 b-31 y-44" />
            </div>
        </div>
    );
}

export const RightPath = () => {
    return (
        <div className="weight-2 flexColumn">
            <div className="weight-1 flexRow">
                <Box classes="g-31 r-44 b-05 y-18" />
                <Box classes="g-32 r-45 b-06 y-19" />
                <Box classes="g-33 r-46 b-07 y-20" />
                <Box classes="g-34 r-47 b-08 y-21" isSafeHouse={true} />
                <Box classes="g-35 r-48 b-09 y-22" />
                <Box classes="g-36 r-49 b-10 y-23" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g--- r-55 b--- y--- red" />
                <Box classes="g--- r-54 b--- y--- red" />
                <Box classes="g--- r-53 b--- y--- red" />
                <Box classes="g--- r-52 b--- y--- red" />
                <Box classes="g--- r-51 b--- y--- red" />
                <Box classes="g-37 r-50 b-11 y-24" angle={"left"} />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-43 r-04 b-17 y-30" />
                <Box classes="g-42 r-03 b-16 y-29" />
                <Box classes="g-41 r-02 b-15 y-28" />
                <Box classes="g-40 r-01 b-14 y-27" />
                <Box classes="g-39 r-00 b-13 y-26 red" isHouse={true} />
                <Box classes="g-38 r--- b-12 y-25" />
            </div>
        </div>
    );
}

export const TopPath = () => {
    return (
        <div className="weight-1 flexColumn">
            <div className="weight-1 flexRow">
                <Box classes="g-23 r-36 b-49 y-10" />
                <Box classes="g-24 r-37 b-50 y-11" angle={"down"} />
                <Box classes="g-25 r-38 b--- y-12" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-22 r-35 b-48 y-09" />
                <Box classes="g--- r--- b-51 y--- blue" />
                <Box classes="g-26 r-39 b-00 y-13 blue" isHouse={true} />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-21 r-34 b-47 y-08" isSafeHouse={true} />
                <Box classes="g--- r--- b-52 y--- blue" />
                <Box classes="g-27 r-40 b-01 y-14" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-20 r-33 b-46 y-07" />
                <Box classes="g--- r--- b-53 y--- blue" />
                <Box classes="g-28 r-41 b-02 y-15" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-19 r-32 b-45 y-06" />
                <Box classes="g--- r--- b-54 y--- blue" />
                <Box classes="g-29 r-42 b-03 y-16" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-18 r-31 b-44 y-05" />
                <Box classes="g--- r--- b-55 y--- blue" />
                <Box classes="g-30 r-43 b-04 y-17" />
            </div>
        </div>
    );
}

export const BottomPath = () => {
    return (
        <div className="weight-1 flexColumn">
            <div className="weight-1 flexRow">
                <Box classes="g-04 r-17 b-30 y-43" />
                <Box classes="g-55 r--- b--- y--- green" />
                <Box classes="g-44 r-05 b-18 y-31" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-03 r-16 b-29 y-42" />
                <Box classes="g-54 r--- b--- y--- green" />
                <Box classes="g-45 r-06 b-19 y-32" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-02 r-15 b-28 y-41" />
                <Box classes="g-53 r--- b--- y--- green" />
                <Box classes="g-46 r-07 b-20 y-33" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-01 r-14 b-27 y-40" />
                <Box classes="g-52 r--- b--- y--- green" />
                <Box classes="g-47 r-08 b-21 y-34" isSafeHouse={true} />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g-00 r-13 b-26 y-39 green" isHouse={true} multiSelect={false} />
                <Box classes="g-51 r--- b--- y--- green" />
                <Box classes="g-48 r-09 b-22 y-35" />
            </div>
            <div className="weight-1 flexRow">
                <Box classes="g--- r-12 b-25 y-38" />
                <Box classes="g-50 r-11 b-24 y-37" angle={"up"} />
                <Box classes="g-49 r-10 b-23 y-36" />
            </div>
        </div>
    );
}

const Box = (args) => {
    let number = -1;
    if (args.classes.indexOf("b-") > -1) {
        number = args.classes.substr(args.classes.indexOf("b-") + 2, 2);
    }
    return (
        <div className={`weight-1 boxes ` + args.classes + ` ${(args.isHouse || args.isSafeHouse) ? "safe" : "unsafe"}`} >
            <div className='tokenContainer'>
            </div>
            <span className='tokenScoreBox'>
                <span className='tokenScore'></span>
            </span>
            <div className='MultiTokenSelectorModal'>
                {
                    args.multiSelect ? getMultiSelectToken() : <></>
                }
            </div>
            <div className='inner-icon'>
                {
                    args.isHouse ? <i className="far fa-star animate" /> : ""
                } {
                    args.isSafeHouse ? <i className="fa fa-star" /> : ""
                } {
                    args.angle ? <i className={`fa-solid fa-angle-` + args.angle} /> : ""
                }
                {/* {number > -1 ? number : "--"} */}
                {/* <i className="far fa-question animate text-success questionMark d-none" /> */}
                <div className='questionMark animate d-none' style={{lineHeight: "0px"}}>
                    <QuestionMarkIcon />
                </div>
            </div>
        </div>
    );
}

const getMultiSelectToken = () => {
    return (
        <div className='TokenTurnModal'>
            <div className='tokenTurnContent'>
                <h5 className='text-white mb-2 text-center'>Select Pawn</h5>
                <button className='close'><i className="fa fa-close"></i></button>
                <div className='tokenTurnBody'>
                    <span className='tokenTurnBox'>
                        <span className='token-div'> <img src={`assets/img/game_play/token_green.png`} alt="Token" className="img2" /></span>
                        <span className='tokenTurnScore'>15</span>
                    </span>
                    <span className='tokenTurnBox'>
                        <span className='token-div'> <img src={`assets/img/game_play/token_green.png`} alt="Token" className="img2" /></span>
                        <span className='tokenTurnScore'>10</span>
                    </span>
                    <span className='tokenTurnBox'>
                        <span className='token-div'> <img src={`assets/img/game_play/token_green.png`} alt="Token" className="img2" /></span>
                        <span className='tokenTurnScore'>10</span>
                    </span>
                    <span className='tokenTurnBox'>
                        <span className='token-div'> <img src={`assets/img/game_play/token_green.png`} alt="Token" className="img2" /></span>
                        <span className='tokenTurnScore'>10</span>
                    </span>
                </div>
            </div>
        </div>
    );
}

export const Home = () => {
    return (
        <div className="weight-1 home-box">
            <div className="square-triangle">
                <span className="blue-shape" />
                <span className="yellow-shape" />
                {/* <span className="home-shape g-56 r-56 b-56 y-56">
                    <div className='tokenContainer'></div>
                    <div className='inner-icon'>
                        <img src="assets/img/home.png" />
                    </div>
                </span> */}
                <span className="home-shape">
                    <span className="g-56 home-green">
                        <div className='tokenContainer'></div>
                    </span>
                    <span className="r-56 home-red">
                        <div className='tokenContainer'></div>
                    </span>
                    <span className="b-56 home-blue">
                        <div className='tokenContainer'></div>
                    </span>
                    <span className="y-56 home-yellow">
                        <div className='tokenContainer'></div>
                    </span>
                    <div className='inner-icon'>
                        <img src="assets/img/home.png" />
                    </div>
                </span>
                <span className="green-shape" />
                <span className="red-shape" />
            </div>
        </div>
    );
}