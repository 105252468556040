export const QuestionMarkIcon = (props) => (
    <svg
        width={9}
        height={12}
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M5.062.053C3.4.053 2.322.756 1.477 2.01a.527.527 0 0 0 .108.706l.898.703a.49.49 0 0 0 .693-.089c.522-.674.91-1.062 1.724-1.062.641 0 1.434.425 1.434 1.066 0 .484-.388.733-1.02 1.1-.739.426-1.715.957-1.715 2.285v.21c0 .285.223.516.5.516h1.51c.276 0 .5-.23.5-.516v-.124c0-.92 2.61-.959 2.61-3.45 0-1.877-1.888-3.3-3.657-3.3m-.208 8.024c-.796 0-1.444.668-1.444 1.488s.648 1.489 1.444 1.489 1.443-.668 1.443-1.489c0-.82-.648-1.488-1.443-1.488"
                fill="#3CBA9C"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.854.053h8v11h-8z" />
            </clipPath>
        </defs>
    </svg>
);

export const RightAnswerIcon = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={8.072} cy={7.915} r={6.85} stroke="#2DE538" strokeWidth={1.3} />
        <path
            d="m4.842 8.126 2.1 1.968 4.359-4.358"
            stroke="#2DE538"
            strokeWidth={1.3}
            strokeLinecap="round"
        />
    </svg>
);

export const WrongAnswerIcon = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={8.18} cy={7.915} r={6.85} stroke="#FF3806" strokeWidth={1.3} />
        <path
            d="m5.58 10.514 5.199-5.199m0 5.199L5.58 5.315"
            stroke="#FF3806"
            strokeWidth={1.3}
            strokeLinecap="round"
        />
    </svg>
);