import React, { useEffect, useState } from "react";

const Result = ({ winners, loggedInPlayerId, leaveTable }) => {
    const [myData, setMyData] = useState(null);

    const getMyData = () => {
        let myData = null;
        for (let i = 0; i < winners.length; i++) {
            if (loggedInPlayerId === winners[i].playerId) {
                myData = winners[i];
                myData["position"] = i + 1;
                break;
            }
        }
        setMyData(myData);
    };

    useEffect(() => {
        getMyData();
    }, [myData]);

    return (
        <>
            <div
                className="result"
                style={{
                    height: "100%",
                    position: "fixed",
                    background: "radial-gradient(50% 50% at 50% 50%, #454545 0%, #201F1F 100%)",
                    width: "100%",
                }}
            >
                <div className="px-2">
                    <div className="py-2">
                        <span className="back-btn" onClick={() => leaveTable()}>
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        width: "360px",
                        margin: "0 auto",
                    }}
                >
                    <div className="px-2" style={{ flex: "1" }}>
                        <div className="">
                            <h5 className="text-center text-white" style={{ marginBottom: "60px" }}>
                                MATCH RESULTS
                            </h5>
                            {myData ? (
                                <>
                                    <div className="balance-card bg-dark-light p-3 px-4">
                                        <div className="avatar-box">
                                            <img src={myData.avatar ? myData.avatar : "/assets/img/game_play/default-avatar.png"} />
                                            <img className="avatar-animation" src="/assets/img/game_play/animation-img.png" alt="" />
                                        </div>
                                        <div className="d-flex justify-content-between text-center mt-3">
                                            <div>
                                                <p className="text-white font-size-12 mb-1"> RANK </p>
                                                <div className="rank-icon">
                                                    {myData.winningPosition ? (
                                                        <>
                                                            <img src="/assets/img/game_play/medal.png" alt="" />
                                                            <span className="rank-text">{myData.winningPosition}</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="rank-text">{myData.position}</span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <p className="text-white font-size-12 mb-1">{myData.playerName}</p>
                                                {myData.winningPosition ? (
                                                    <>
                                                        <h5 className="text-green fw-700 font-size-18">
                                                            YOU WON <br />{myData.winningAmount.toFixed(2)}
                                                        </h5>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h5 className="text-green fw-700 font-size-18">
                                                            YOU LOST ! <br />
                                                            Lets Try Again
                                                        </h5>
                                                    </>
                                                )}
                                            </div>
                                            <div>
                                                <p className="text-white font-size-12 mb-1">SCORE</p>
                                                <h4 className="fw-700 text-green font-size-18">{myData.winningAmount === -1 ? "K.O." : myData.countScore}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            <div className="table-responsive winning-table mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="">RANK</th>
                                            <th></th>
                                            <th className="text-center">SCORE</th>
                                            <th className="text-center">WINNINGS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {winners.map((winner, index) => (
                                            <tr key={index} className={winner.playerId === loggedInPlayerId ? "bg-dark-light" : ""}>
                                                <td colSpan={2} className="responsive-table__body__text">
                                                    {index + 1}
                                                    <span className="user-icon mx-4">
                                                        <img src={winner.avatar ? winner.avatar : "/assets/img/game_play/default-avatar.png"} alt={`User ${index + 1}`} />
                                                    </span>
                                                    {winner.playerName}
                                                </td>
                                                <td className="responsive-table__body__text text-center">{winner.countScore === -1 ? "K.O." : winner.countScore}</td>
                                                <td className="responsive-table__body__text text-center">{winner.winningAmount === -1 ? "0.00" : `${winner.winningAmount.toFixed(2)}`}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="text-center py-3">
                        <button className="btn custom-green-btn" onClick={() => leaveTable()}>
                            {" "}
                            Back to Home{" "}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Result;
