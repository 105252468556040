import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
// import './App.css';
import "./Theme-0.css";
import "./Theme-2.css";
import "./CountDownTimer.css";
import Board from "./components/Board";
// import Board from './components/Board-Bkp';
import Dashboard from "./components/Dashboard";
import Error404 from "./components/Error404";
// import "./media-queries.css";
import "./media-queries-0.css";
import "./media-queries-2.css";
import { AccessTokenKey, getDataFromStore, setDataInStore } from "./utils/DataStore";
import { useEffect, useLayoutEffect } from "react";

function App() {
    const urlParams = new URLSearchParams(window.location.search);
    let accessToken = urlParams.get("at");
    // let hash = getUrlParamValue("hash");

    useLayoutEffect(() => {
        if (process.env.REACT_APP_THEME !== "") {
            const bodyTag = document.getElementsByTagName("body")[0];
            bodyTag.className = "";
            bodyTag.classList.add(process.env.REACT_APP_THEME);
        }
    }, []);
    if (
        !accessToken &&
        !getDataFromStore(AccessTokenKey)
        // && !hash && !getDataFromStore("hash")
    ) {
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Error404 />} />
                    </Routes>
                </BrowserRouter>
            </>
        );
    }

    if (accessToken) {
        setDataInStore(AccessTokenKey, accessToken);
    }
    // if (hash) setDataInStore("hash", hash);

    const getRoutes = () => {
        return (
            <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/game" element={<Board />} />
                <Route path="/unauthorized" element={<Error404 />} />
                <Route path="*" element={<Navigate to={"/"} />} />
            </>
        );
    };

    return (
        <BrowserRouter>
            <Routes>{getRoutes()}</Routes>
        </BrowserRouter>
    );
    // return (
    //     <>
    //         <div className='main-div'>
    //             <Board />
    //             {/* <Matching/> */}
    //             {/* <Result/> */}
    //         </div>
    //     </>
    // );
}

export default App;
