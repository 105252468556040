import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { callGetApi } from "../api/Apicaller";
import { ClientId, Get_My_Matching_Templates_GET, Get_My_Running_Games_GET, Get_Profile } from "../api/ApiConst";
import { Get_Template_List_GET } from "../api/ApiConst";
import { NO_ERROR } from "../api/constants";
import { AccessTokenKey, AuthTokenKey, TableIdKey, TemplateIdKey, UserDetailsKey, deleteDataFromStore, getDataFromStore, setDataInStore } from "../utils/DataStore";
import Board from "./Board";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import ModalBox from "./common/Modal";
import InnerHTML from "dangerously-set-html-content";

const Dashboard = () => {
    const [showRulesModal, setShowRulesModal] = useState(false);
    const [ruleHtml, setRuleHtml] = useState("");
    const navigate = useNavigate();
    const [sliderDetails, setSliderDetails] = useState([1, 2, 3]);
    var settings = {
        dots: false,
        infinite: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return <img src="assets/img/game_play/right-arrow.png" className={`slick-next ${className}`} style={{ right: "0" }} onClick={onClick} />;
    }

    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return <img src="assets/img/game_play/left-arrow.png" className={`slick-prev ${className}`} style={{ left: "0" }} onClick={onClick} />;
    }

    const init = () => {
        let accessToken = getDataFromStore(AccessTokenKey);
        if (accessToken === null) {
            navigate("/unauthorized");
            return;
        }
        getProfile();
    };
    useEffect(() => {
        init();
        window.parent.postMessage("Dashboard", "*");
    }, []);

    const [templateListData, setTemplateListData] = useState([]);
    const [runningGamesData, setRunningGamesData] = useState([]);
    const [showMyRunningGamesModel, setShowMyRunningGamesModel] = useState(false);

    const getProfile = () => {
        callGetApi(Get_Profile.replaceAll("{clientId}", ClientId), {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setDataInStore(UserDetailsKey, response.responsePacket);
                getTemplateList();
                getMyRunningGameList();
                getMyMatchingTemplateList();
            } else {
                // toast.error(response.message, { id: "avatarList" });
            }
        });
    };

    const getTemplateList = () => {
        callGetApi(Get_Template_List_GET.replaceAll("{clientId}", ClientId), {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setTemplateListData(response.responsePacket);
            } else {
                // toast.error(response.message, { id: "avatarList" });
            }
        });
    };

    const getMyRunningGameList = () => {
        callGetApi(Get_My_Running_Games_GET.replaceAll("{clientId}", ClientId), {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                // TO Auto Join Table
                if (response.responsePacket.length > 0) {
                    joinBack(response.responsePacket[0].tableId);
                }
                // setRunningGamesData(response.responsePacket);
                // setShowMyRunningGamesModel(response.responsePacket.length > 0);
            } else {
                // toast.error(response.message, { id: "avatarList" });
            }
        });
    };

    const getMyMatchingTemplateList = () => {
        callGetApi(Get_My_Matching_Templates_GET.replaceAll("{clientId}", ClientId), {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                if (response.responsePacket.length > 0) {
                    joinGame(response.responsePacket[0]);
                }
            } else {
                // toast.error(response.message, { id: "avatarList" });
            }
        });
    };

    const joinGame = (templateRecord) => {
        // Board > templateRecord.recordId
        deleteDataFromStore(TemplateIdKey);
        deleteDataFromStore(TableIdKey);
        setDataInStore(TemplateIdKey, templateRecord);
        navigate(`/game`);
    };
    const joinBack = (tableId) => {
        // Board > table.tableId
        deleteDataFromStore(TemplateIdKey);
        deleteDataFromStore(TableIdKey);
        setDataInStore(TableIdKey, tableId);
        navigate(`/game`);
    };
    const totalDistributionPercentage = (everyWinnerWinningPercentage) => {
        let totalDistribution = 0;
        for (let i = 0; i < everyWinnerWinningPercentage.length; i++) {
            totalDistribution += everyWinnerWinningPercentage[i];
        }
        return totalDistribution / 100.0;
    };
    const showRules = (template) => {
        if (template.boardRule === null || template.boardRule === undefined || template.boardRule === "") {
            return;
        }
        setShowRulesModal(true);
        setRuleHtml(template.boardRule);
    };

    const getTemplateListView = () => {
        return (
            <>
                <div className="theme_2MainContent">
                    <div className="dashboardList ">
                        <div className=" overflow-auto" style={{ flex: "1" }}>
                            <div className="row m-0 w-100 justify-content-center">
                                {templateListData.map((item) => (
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 " key={item.recordId}>
                                        <div className="templateListCard">
                                            <div className="p-3 pt-1">
                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <div className="col-8 p-0">
                                                        <div className="text-white fs-18 mb-2">{item.templateTitle}</div>
                                                    </div>
                                                    <div className=" col-4 p-0">
                                                        <div className="d-flex justify-content-between align-content-center ">
                                                            <div className="d-flex justify-content-between align-items-center fs-12" style={{ gap: "5px" }}>
                                                                <span>
                                                                    <img src="assets/img/game_play/timerIcon.png" alt="" />
                                                                </span>
                                                                <span className="text-white">
                                                                    <span className="d-block">{item.limit}</span>
                                                                    {item.limit === "NoLimit" && <></>}
                                                                    {item.limit === "TurnLimit" && <span>{item.limitValue} Turns</span>}
                                                                    {item.limit === "TimeLimit" && <span>{item.limitValue / 60} Mins</span>}
                                                                </span>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center fs-12" style={{ gap: "5px" }}>
                                                                <span>
                                                                    <img src="assets/img/game_play/ludoIcon.png" alt="" />
                                                                </span>
                                                                <span className="text-white">{item.playerCount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <div className=" d-flex">
                                                        <div className="mr-3">
                                                            <img src="assets/img/game_play/trophyIcon.png" alt="" />
                                                        </div>
                                                        <div>
                                                            <div className="text-white fs-12">Pool Prize</div>
                                                            <div className="text-yellow fs-18">{(item.betAmount + item.feeAmount).toFixed(2)}</div>
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <button className="btn border-0 bgRedLight text-white fs-12 fw-400" onClick={() => joinGame(item.recordId)}>
                                                            PLAY NOW
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="theme_0MainContent">
                    <div className="dashboard-body">
                        <div className="dashboard-content">
                            <Slider {...settings}>
                                {templateListData?.map((item) => (
                                    <div className="px-3" key={item.recordId}>
                                        <div className="slider-card">
                                            <div className="owl-carousel owl-theme">
                                                <div className="item">
                                                    <div className="item-box slider-block">
                                                        <span className="underline-btn cursor-pointer">
                                                            <span className="Q-div" onClick={() => showRules(item)}>
                                                                <img src="assets/img/game_play/rules.png" alt="Rules" style={{ height: 14 }} />
                                                            </span>
                                                        </span>
                                                        <div className=" text-center">
                                                            <span className="entry-fee">
                                                                <p className="mb-0">Entry Fee</p>
                                                                <h5 className="mb-0">{(item.betAmount + item.feeAmount).toFixed(2)}</h5>
                                                            </span>
                                                        </div>
                                                        <div className="jackpot-div">
                                                            <p className="my-4">{item.templateTitle}</p>
                                                            <h6 className="mt-2 mb-0">Pool Prize</h6>
                                                            <h4 className="m-0">{(item.betAmount * item.playerCount * totalDistributionPercentage(item.everyWinnerWinningPercentage)).toFixed(2)}</h4>
                                                        </div>
                                                        <div className="player-div mx-2 mt-1 mb-2">
                                                            <div className="row">
                                                                <div className="col-6 border-right">
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="player-icon-div">
                                                                            <img src="assets/img/game_play/player-icon.png" alt="Player" />
                                                                        </div>
                                                                        <h4>
                                                                            <span style={{ color: "rgb(142, 128, 228)" }}>Players</span>
                                                                            <br />
                                                                            {item.playerCount}
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className=" d-flex align-items-center">
                                                                        <div className="player-icon-div">
                                                                            <img src="assets/img/game_play/game-turn.png" />
                                                                        </div>
                                                                        <h4>
                                                                            <span className="d-block" style={{ color: "rgb(142, 128, 228)" }}>
                                                                                {item.limit}
                                                                            </span>
                                                                            {item.limit === "NoLimit" && <></>}
                                                                            {item.limit === "TurnLimit" && <span>{item.limitValue} Turns</span>}
                                                                            {item.limit === "TimeLimit" && <span>{item.limitValue / 60} Mins</span>}
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-center mt-2 ">
                                                                <button className="btn custom-green-btn w-100 rounded-0 cursor-pointer" onClick={() => joinGame(item.recordId)}>
                                                                    Join Game
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                <ModalBox show={showRulesModal} toggleModel={() => setShowRulesModal(false)} modalClass={"sliderBlockModal"}>
                    <div className="modal-content bg-purple border-none">
                        <div className="modal-body pt-0 customScroll">
                            <div className="modal-header modal-body-header custom-border-light">
                                <button type="button" className="close btn-close" data-dismiss="modal" aria-label="Close" onClick={() => setShowRulesModal(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/game_play/modal-close.png" alt="" style={{ width: 25 }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-yellow font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                    RULES
                                </h5>
                            </div>
                            <div
                                className="pt-4 px-2 "
                                style={{
                                    height: "370px",
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                }}
                            >
                                <div
                                    className="align-items-center mb-3"
                                    style={{
                                        fontSize: "15px",
                                        color: "white",
                                    }}
                                >
                                    <InnerHTML html={ruleHtml} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            </>
        );
    };

    const getMyRunningGameListView = () => {
        return (
            <>
                <div className={`modal custom-modal ${showMyRunningGamesModel ? "show d-block" : ""}`} id="playersModal">
                    <div className="modal-dialog " role="document">
                        <div className="modal-content bg-purple border-none w-100 ">
                            <div className="modal-body pt-0 customScroll">
                                <div className="modal-header modal-body-header custom-border-light">
                                    <button
                                        type="button"
                                        className="close btn-close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            setShowMyRunningGamesModel(false);
                                        }}
                                    >
                                        <span className="cursor-pointer">
                                            <img src="assets/img/game_play/modal-close.png" alt="" style={{ width: 25 }} />
                                        </span>
                                    </button>
                                    <h5 className="modal-title text-yellow font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                        My Running Game(s)
                                    </h5>
                                </div>
                                <div className=" text-center">
                                    {runningGamesData?.map((game) => (
                                        <div className="mt-2 custom-border-bottom p-2" key={game.tableId}>
                                            <div className="m-0 slider-card">
                                                <div className="text-white mb-2">
                                                    <div className="player-div mt-2 pb-5 rounded-0 fw-600 font-size-14">
                                                        <h5 className="text-left font-size-16  mb-3">
                                                            Session ID : <span>{game.gameSessionId}</span>
                                                        </h5>
                                                        <h5 className="text-white font-size-16 text-left d-flex justify-content-between">
                                                            <div>
                                                                Entry Fee: <span className="ml-3">{game.feeAmount}</span>
                                                            </div>
                                                            <div>Waiting For Game</div>
                                                        </h5>
                                                        <hr className="my-3" />
                                                        <div className="row mb-4">
                                                            <div className="col-6 border-right">
                                                                <div className=" d-flex align-items-center">
                                                                    <div className="player-icon-div">
                                                                        <img src="assets/img/game_play/player-icon.png" alt="Player" />
                                                                    </div>
                                                                    <h4 className="text-left font-size-16 mb-0">
                                                                        <span style={{ color: "rgb(142, 128, 228)" }}>Players</span>
                                                                        <br /> {game.playingPlayers}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="" />
                                                            </div>
                                                        </div>
                                                        <div className="text-center ">
                                                            <button className="btn custom-green-btn w-100 rounded-0 join-back-btn" onClick={() => joinBack(game.tableId)}>
                                                                Join Back
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {getTemplateListView()}
            {getMyRunningGameListView()}
        </>
    );
};

export default Dashboard;
