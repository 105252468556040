import React, { Component } from 'react';

class AudioUtils extends Component {

    constructor(props) {
        super(props);
        // this.rollDiceAudio = null;
        // this.jumpTokenAudio = null;
        // this.goHomeAudio = null;
        // this.killTokenAudio = null;
    }

    playAudio(whichAudio) {
        if (!window.allowedToPlay) {
            return;
        }
        let audioFilePath = null;
        if (whichAudio === "JUMP_TOKEN") {
            audioFilePath = "gamePlayAssets/audio/Token.ogg";
        } else if (whichAudio === "ROLL_DICE") {
            audioFilePath = "gamePlayAssets/audio/DiceRoll.ogg";
        } else if (whichAudio === "GO_HOME") {
            audioFilePath = "gamePlayAssets/audio/GoHome.ogg";
        } else if (whichAudio === "KILL_TOKEN") {
            audioFilePath = "gamePlayAssets/audio/killer.ogg";
        } else if (whichAudio === "SAFE_HOUSE") {
            audioFilePath = "gamePlayAssets/audio/safe_house.ogg";
        } else if (whichAudio === "MY_TURN") {
            audioFilePath = "gamePlayAssets/audio/my_turn.ogg";
        }
        if (audioFilePath != null) {
            let audio = new Audio(audioFilePath);
            try {
                audio.volume = 0.2;
                audio.play().catch((e) => {
                    console.log(e);
                });
            } catch (err) {
                console.log(err);
            }
        }
    }

    /* 
    <div className="d-none">
        <audio id="ROLL_DICE" controls>
            <source src="gamePlayAssets/audio/DiceRoll.ogg" type="audio/ogg" />
        </audio>
        <audio id="JUMP_TOKEN" controls>
            <source src="gamePlayAssets/audio/Token.ogg" type="audio/ogg" />
        </audio>
        <audio id="GO_HOME" controls>
            <source src="gamePlayAssets/audio/GoHome.ogg" type="audio/ogg" />
        </audio>
        <audio id="KILL_TOKEN" controls>
            <source src="gamePlayAssets/audio/killer.ogg" type="audio/ogg" />
        </audio>
    </div>
    // playAudio(whichAudio) {
    //     if (this.rollDiceAudio === null) {
    //         this.rollDiceAudio = document.getElementById("ROLL_DICE");
    //         this.jumpTokenAudio = document.getElementById("JUMP_TOKEN");
    //         this.goHomeAudio = document.getElementById("GO_HOME");
    //         this.killTokenAudio = document.getElementById("KILL_TOKEN");
    //     }
    //     if (whichAudio === "ROLL_DICE") {
    //         this.rollDiceAudio.play();
    //     } else if (whichAudio === "JUMP_TOKEN") {
    //         this.jumpTokenAudio.play();
    //     } else if (whichAudio === "GO_HOME") {
    //         this.goHomeAudio.play();
    //     } else if (whichAudio === "KILL_TOKEN") {
    //         this.killTokenAudio.play();
    //     }
    //     // document.getElementById(whichAudio).play();
    // }
    */
}

export default AudioUtils;