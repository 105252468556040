import React, { useEffect } from 'react'

var spinTimerInterval = null;
const Spin = ({ timer = 0 }) => {
    useEffect(() => {
        if (spinTimerInterval !== null && spinTimerInterval !== undefined) {
            clearInterval(spinTimerInterval);
        }
        var time = timer;
        var i = 1;
        var k = ((i / timer) * 100);
        var l = 100 - k;
        i++;
        const c1Ele = document.getElementById("c1")
        const c2Ele = document.getElementById("c2")
        const counterTextEle = document.getElementById("counterText")

        if (c1Ele) {
            c1Ele.style.strokeDasharray = [l, k];
            c1Ele.style.strokeDashoffset = l;
        }
        if (c2Ele) {
            c2Ele.style.strokeDasharray = [k, l];
        }
        if (counterTextEle) {
            counterTextEle.innerHTML = timer;
        }

        spinTimerInterval = setInterval(function () {
            if (i > time) {
                clearInterval(spinTimerInterval);
                try {
                    document.getElementById("countdownsvg").style.display = "none"
                    var loaderTimeEl = document.getElementById('loaderTime');
                    if (loaderTimeEl) {
                        loaderTimeEl.style.display = "none";
                    }
                } catch (err) { }
                return;
            }
            k = ((i / timer) * 100);
            l = 100 - k;
            if (c1Ele && c2Ele) {
                c1Ele.style.strokeDasharray = [l, k];
                c2Ele.style.strokeDasharray = [k, l];
                c1Ele.style.strokeDashoffset = l;
            }
            if (counterTextEle) {
                counterTextEle.innerHTML = (timer + 1) - i;
            }
            i++;
        }, 1000);

        return (() => {
            if (spinTimerInterval !== null && spinTimerInterval !== undefined) {
                clearInterval(spinTimerInterval);
            }
        });
    }, [])

    return (
        <>
            <div className="slot-machine-div" id="loaderTime">
                <div className="position-relative ">
                    <svg
                        width="200px"
                        height="200px"
                        viewBox="0 0 42 42"
                        className="donut"
                        id="countdownsvg"
                    >
                        <circle
                            id="c1"
                            cx={21}
                            cy={21}
                            r="15.91549430918954"
                            strokeDasharray="100 0"
                            strokeDashoffset={100}
                        />
                        <circle
                            id="c2"
                            cx={21}
                            cy={21}
                            r="15.91549430918954"
                            strokeDasharray="0 100"
                            strokeDashoffset={0}
                        />
                        <g className="chart-text">
                            <text
                                x="50%"
                                y="55%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                id="counterText"
                            />
                        </g>
                    </svg>
                    <div id="countdown">
                        <div id="countdown-number" />
                    </div>
                    {/* <div className="slot-machine" style={{ display: "none" }} id="slotMachine">
                        <div className="group">
                            <div className="reel"></div>
                            <div className="reel"></div>
                            <div className="reel"></div>
                            <div className="reel"></div>
                            <div className="reel"></div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default Spin;