import { Component } from 'react';
import { Stomp } from '@stomp/stompjs';

class StompClient extends Component {
    constructor(props) {
        super(props);
        this.host = props.host;
        this.username = props.username;
        this.password = props.password;
        this.exchange = props.exchange;
        this.destinationList = props.destinationList;
        this.onConnectCallBack = props.onConnectCallBack;
        this.onMessageReceivedCallBack = props.onMessageReceivedCallBack;
        this.onDisconnect = props.onDisconnect;
        this.reconnectCount = 0;
        this.disconnectSocket = false;
    }

    initSocket = () => {
        let _that = this;
        if (_that.host) {
            let socket = new WebSocket(_that.host);
            _that.stompClient = Stomp.over(socket);
            _that.stompClient.heartbeat = {
                outgoing: 5000,
                incoming: 5000
            };
            _that.stompClient.reconnect_delay = 2000;

            _that.stompClient.debug = function (e) {
                // console.log("debug", e);
                if (!_that.disconnectSocket && e.indexOf("Connection closed") > -1) {
                    setTimeout(() => {
                        if (_that.reconnectCount < 3) {
                            _that.reconnectCount = _that.reconnectCount + 1;
                            _that.initSocket();
                            // console.log("first");
                        } else {
                            // Go Back to Home > open model.
                            // Unable to connect to the host, please try again > Go Back
                            _that.onDisconnect();
                        }
                    }, 1000);
                }
            };
            _that.stompClient.connect(_that.username, _that.password, (frame) => {
                // _that.stompClient = client;
                for (let i = 0; i < _that.destinationList.length; i++) {
                    _that.stompClient.subscribe(_that.destinationList[i], function (message) {
                        const res = JSON.parse(message.body);
                        _that.onMessageReceivedCallBack(res);
                    });
                }
                _that.onConnectCallBack();
            }, (error) => {
                console.error('Connection error: ', error);
            });
        }
        return () => _that.disconnect();
    }

    publishMessage = (messageBody) => {
        if (this.stompClient.connected) {
            this.stompClient.publish({ destination: `/exchange/${this.exchange}_BroadCast_PublicChannel`, body: messageBody });
        }
    }

    disconnect(onDisconnectCallBack) {
        this.disconnectSocket = true;
        if (this.stompClient.connected) {
            this.stompClient.disconnect();
            onDisconnectCallBack();
        }
    }

    isConnected() {
        return this.stompClient.connected;
    }

    joinTemplatePoolAction = (clientId, authorization, templateId) => {
        let packet = {
            "clientId": clientId,
            "action": "JoinTemplatePool",
            "authorization": authorization,
            "templateId": templateId
        }
        this.publishMessage(JSON.stringify(packet));
    }

    pongAction = (clientId, authorization, templateId) => {
        let packet = {
            "clientId": clientId,
            "action": "Pong",
            "authorization": authorization,
            "templateId": templateId
        }
        this.publishMessage(JSON.stringify(packet));
    }
    
    unJoinTemplatePoolAction = (clientId, authorization, templateId) => {
        let packet = {
            "clientId": clientId,
            "action": "UnJoinTemplatePool",
            "authorization": authorization,
            "templateId": templateId
        }
        this.publishMessage(JSON.stringify(packet));
    }

    leaveTableAction = (clientId, authorization, tableId) => {
        let packet = {
            "clientId": clientId,
            "action": "LeaveTable",
            "authorization": authorization,
            "tableId": tableId
        }
        this.publishMessage(JSON.stringify(packet));
    }

    getTableDetailAction = (clientId, authorization, tableId) => {
        let packet = {
            "clientId": clientId,
            "action": "TableDetail",
            "authorization": authorization,
            "tableId": tableId
        }
        this.publishMessage(JSON.stringify(packet));
    }

    rollDiceAction = (clientId, authorization, tableId) => {
        let packet = {
            "clientId": clientId,
            "action": "RollDice",
            "authorization": authorization,
            "tableId": tableId
        }
        this.publishMessage(JSON.stringify(packet));
    }

    playTokenAction = (clientId, authorization, tableId, tokenIndex) => {
        let packet = {
            "clientId": clientId,
            "action": "PlayToken",
            "authorization": authorization,
            "tableId": tableId,
            "tokenIndex": tokenIndex
        }
        this.publishMessage(JSON.stringify(packet));
    }
    
    postAnswerAction = (clientId, authorization, tableId, answer) => {
        let packet = {
            "clientId": clientId,
            "action": "PostAnswer",
            "authorization": authorization,
            "tableId": tableId,
            "answer": answer
        }
        this.publishMessage(JSON.stringify(packet));
    }
}
export default StompClient;