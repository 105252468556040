import React from 'react';

const Heart = ({ broken }) => {
    return (
        <>
            {
                broken ?
                    <span>
                        <img className="break_heart" alt="break_heart"
                            src="assets/img/game_play/brak_heart.png" />
                    </span>
                    :
                    <span>
                        <img className="correct_heart" alt="heart"
                            src="assets/img/game_play/correct_heart.png" />
                    </span>
            }
        </>
    );
}

export default Heart;